<template>
  <div class="pay-monthly-rent">
    <h1 class="title">{{$t('Insert credit card to finish the payment')}}</h1>
    <div class="monthly-rent mt-4">
      <div class="monthly-rent-text">
        <p v-if="descriptionMessage" class="montserrat-medium rf-blue-color mt-3 mb-1">{{$t(this.descriptionMessage)}}</p>
      </div>
    </div>
    <form @submit.prevent class="payment-credit-card-form" name="payment-form" id="payment-form" enctype="multipart/form-data">
      <b-row class="card-box mt-4">
        <b-col cols="auto">
          <FontAwesomeIcon color="#24425B" :icon="['fas', 'credit-card']" class="mb-2"/>
        </b-col>
        <b-col col>
          <div id="xpay-card"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <RFButton class="mt-4" :label="$t('Done')" background="#24425B" color="#fff" @on-click="insertCreditCard" v-if="!loading"/>
          <RFLoader class="mt-4" :size="30" color="#24425B" v-if="loading"/>
        </b-col>
      </b-row>
      <div v-if="reservation" class="last-payment-div mt-3">
        <p><b>{{$t('Amounts to pay')}}:</b></p>
        <div class="monthly-rent mt-4">
          <div class="monthly-rent-text">
            <p v-if="depositPaymentMethod==='CC'"> <b>{{$t('Deposit')}}:</b> &euro;{{reservation.depositAmount}}</p>
            <p v-if="depositPaymentMethod==='CC'"> <b>{{$t('Service Fees')}}:</b> &euro;{{reservation.serviceFees}}</p>
            <p v-if="monthlyPaymentMethod==='CC'"> <b>{{$t('Monthly rent')}}:</b> &euro;{{reservation.monthlyAmount}} <b>{{$t('(it will be paid after contract start date)')}}</b></p>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'

import { paymentService, userService } from '@/services'
import { mapState } from 'vuex'

export default {
  name: "CreditCardPayment",
  components: {
    RFButton,
    RFLoader,
  },
  props: {
    depositPaymentMethod: {
      type: String,
      default: 'CC'
    },
    monthlyPaymentMethod: {
      type: String,
      default: 'CC'
    },
    creditCardMonthlyPaymentsManual:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      paymentConfig: null,
      loading: false,
      card: null,
      currentReservation: null,
      donePressed: false,
      descriptionMessage: null
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
      user: state => state.userStore.user
    }),
  },
  created() {
    const nexiPayScript = this.nexiPayScript = document.createElement('script')
    nexiPayScript.setAttribute('src', process.env.VUE_APP_PAYMENT_BASE_URL)
    document.head.appendChild(nexiPayScript)
  },
  async beforeMount() {

    if(this.monthlyPaymentMethod==='CC'){
      this.descriptionMessage = 'Insert credit card to pay the deposit and service fees, and monthly payments'
      if(this.creditCardMonthlyPaymentsManual){
        this.descriptionMessage = this.descriptionMessage + ' (manually)'
      }
    }
    else{
      this.descriptionMessage = 'Insert credit card to pay the deposit and service fees'
    }


    if(this.reservation==null){
      const currentReservation = await userService.getReservationById(this.user.id,this.$route.params.id)
      this.$store.commit('profileStore/setReservation', currentReservation)
    }

    this.paymentConfig = await paymentService.getPaymentConfig(this.reservation.id)

    window.addEventListener('load', () => this.initXPay(this.paymentConfig))
  },
  async mounted() {
    window.addEventListener('XPay_Ready', (event) => this.handlerXPayReady(event))
    window.addEventListener('XPay_Payment_Started', (event) => this.handlerXPayStarted(event))
    window.addEventListener('XPay_Card_Error', (event) => this.handlerXPayError(event))
    window.addEventListener('XPay_Nonce', async (event) => await this.handlerXPayNonce(event))
  },
  beforeDestroy() {
    this.nexiPayScript.parentNode.removeChild(this.nexiPayScript)
    this.$store.commit('profileStore/setReservation', null)
  },
  methods: {
    handlerXPayReady(event) {},
    handlerXPayStarted(event) {},
    handlerXPayError(event) {
      if(this.donePressed && event.detail.errorMessage!==undefined){
        this.donePressed = false
        this.$toasted.error(this.$i18n.t(event.detail.errorMessage))
      }
    },
    async handlerXPayNonce(event) {
      this.loading = true

      const { detail: response } = event

      const { dettaglioCarta, idOperazione, esito, xpayNonce, timeStamp, mac, errore } = response


      if (esito === 'OK') {

        const form = {
          apiKey: this.paymentConfig.apiKey,
          codiceTransazione: this.paymentConfig.codiceTransazione,
          importo: this.paymentConfig.amount,
          divisa: this.paymentConfig.currency,
          xpayNonce,
          idOperazione,
          timeStamp,
          esito,
          mac,
          dettaglioCarta,
          numeroContratto: this.reservation.numeroContratto,
        }

        let confirm;

        //Check the different cases with CC
        if(this.depositPaymentMethod==='CC'){
          //Case 1
          if(this.monthlyPaymentMethod==='CC'){
            //Automatic monthly payments: Normal CC flow
            if(!this.creditCardMonthlyPaymentsManual){
              confirm = await paymentService.confirmPayment(form)
            }
            //Manual monthly payments
            else{
              confirm = await paymentService.confirmPaymentWithMonthlyPaymentsManual(form)
            }
          }
          //Case 2
          else{
            confirm = await paymentService.confirmPaymentWithBankTransferMonthlyPayments(form)
          }

          if (confirm.esito === 'KO') {
            this.$toasted.error(this.$i18n.t(`The transaction was rejected. Please try again.`))
            window.setTimeout(() => window.location = this.$route.path, 1000)
            return false
          }
          this.$toasted.success(this.$i18n.t(`The transaction was successful!`))
        }

        this.$store.commit('profileStore/setReservation', null)
        this.$router.push({ name: 'your-roof' })
      }
      else{
        this.$toasted.error(this.$i18n.t(`${errore.messaggio}`))
      }
      this.loading = false
    },
    initXPay(paymentConfig) {

      XPay.init()

      this.setNexiConfig(paymentConfig)
      XPay.setInformazioniSicurezza({})

      const style = {
        common: {
          color: '#000',
          ':hover': {
            color: '#000'
          }
        },
        error: {
          color: '#FF5A5F',
        },
        correct: {
          color: '#5CB85C',
        }
      }

      const card = this.card = XPay.create(XPay.OPERATION_TYPES.CARD, style)
      card.mount('xpay-card')
    },
    setNexiConfig(paymentConfig){
      const { apiKey, environment, amount, codiceTransazione, currency, timeStamp, mac, language } = paymentConfig
      let url = `${process.env.VUE_APP_BASE_URL}/payments/access/${this.reservation.id}`
      let urlBack = `${process.env.VUE_APP_BASE_URL}/payments/confirm`

      //Modify the urls depending on the case (default case 1 automatic)

      //Case 1 (monthly payments manual)
      if(this.creditCardMonthlyPaymentsManual){
        urlBack = `${process.env.VUE_APP_BASE_URL}/payments/confirmManual`
      }

      //Case 2
      if(this.monthlyPaymentMethod==='BT'){
        urlBack = `${process.env.VUE_APP_BASE_URL}/payments/confirmUnique`
      }

      let config = {
        baseConfig: {
          apiKey,
          enviroment: environment//=XPay.Environments.INTEG
        },
        paymentParams: {
          amount,
          transactionId: codiceTransazione,
          currency,
          timeStamp,
          mac,
          url,
          urlBack,
        },
        customParams: {},
        language,//=XPay.LANGUAGE.ITA,
      }
      //If is case 1 (monthly payment with CC) automatic, set the corresponding properties
      if(this.monthlyPaymentMethod==='CC' && !this.creditCardMonthlyPaymentsManual){
        config.serviceType = 'paga_multi'
        config.requestType = 'PP'
      }

      XPay.setConfig(config)

    },
    insertCreditCard(){
      this.donePressed = true
      XPay.createNonce('payment-form', this.card)
    },
  }
}
</script>

<style lang="scss" scoped>
.pay-monthly-rent {
  min-height: 80vh;
  margin-top: 30px;

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: .5px solid $dark-gray;
  }
  .payment-credit-card-form {
    .card-box {
      margin: 0 5px;
      padding-top: 10px;
      border: 1px solid $dark-blue;
    }
  }
  .last-payment-div {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
  }
  .monthly-rent-text {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: var(--color);

    .mail {
      color: var(--color);
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .pay-monthly-rent {
    padding-left: 50px;
    margin-top: 0;
  }
}
</style>
